// src/routes.js
import React, { Suspense, } from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import BusinessProfile from "./pages/BusinessProfile";
import { Signin } from "./pages/Login/Signin";

const AppRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route
        path="/business-profile/*"
        element={<PrivateRoute component={BusinessProfile} />}
      />
      <Route path="*" element={<PageNotFound/>} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
