import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  Typography,
  Grid,
  FormControlLabel,
  InputLabel,
  Paper,
} from "@mui/material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import DataContext from "../../DataContext";
import { getProducts, setupProfile } from "../../api/querise";
import { useSnackbar } from "../../hooks/Snackbar";

const ProfileSetup = ({setActiveStep,onNext}) => {
  const [shouldPost, setShouldPost] = useState(false);
  const [errors, setErrors] = useState({});
  const { uniqueId, formData, setFormData ,setProductKey} = useContext(DataContext);
  const [shoulget, setShouldGet] = useState(false);
  const loginEmail = localStorage.getItem("email");
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();

  const { data, error } = useQuery(["users", formData, uniqueId], setupProfile, {
    enabled: shouldPost,
    retry: false,
  });

  const { data:getProductData, error:getProductErrror } = useQuery(["users"], getProducts, {
    enabled: shoulget,
    retry: false,
  });


  useEffect(() => {
    if (getProductData) {
      setProductKey(getProductData)
      setShouldGet(false);

    
    }else if(getProductErrror){
      setShouldGet(false);
    }
    // eslint-disable-next-line
  },[getProductData]);

  useEffect(() => {
    setShouldGet(false);
    setShouldGet(true);
  }, []);


  useEffect(() => {
    setShouldPost(false);
    if (data ) {
      setActiveStep(1);
      setShouldGet(false);
      queryClient.removeQueries(["users", formData, uniqueId]);
      onNext()
    } else if (error) {
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");
      setShouldPost(false);
    }
    // eslint-disable-next-line
  }, [data,formData,uniqueId,queryClient, error, setActiveStep]);
  

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "useDefaultEmail") {
      setFormData({
        ...formData,
        useDefaultEmail: checked,
        primary_email: checked ? loginEmail : "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleContinue = () => {
    const newErrors = {};
    const { company_name, primary_email, country, state, city, street_address, zip_code } = formData;

    if (!company_name) newErrors.company_name = "Company name is required.";
    if (!primary_email) newErrors.primary_email = "Primary email is required.";
    if (!country) newErrors.country = "Country is required.";
    if (!state) newErrors.state = "State is required.";
    if (!city) newErrors.city = "City is required.";
    if (!street_address) newErrors.street_address = "Street address is required.";
    if (!zip_code) newErrors.zip_code = "ZIP code is required.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setShouldPost(true);
    }
  };

  return (
      <Paper sx={{ p: 2, borderRadius: "12px",boxShadow:'none' }}>
        <Typography
          sx={{
            color: "#283246",
            fontSize: "1.625rem",
            fontWeight: 700,
            fontFamily: "DM Sans, Arial, sans-serif",
            mb: 2,
            display: 'flex',
            mt:2,
          }}
        >
          Business Profile Setup
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel sx={{ ml: 1, mb: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>Company Name</InputLabel>
              <TextField
                name="company_name"
                placeholder="Enter Company Name"
                value={formData.company_name}
                onChange={handleChange}
                fullWidth
                error={!!errors.company_name}
                helperText={errors.company_name}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    height: "46px",
                    width: '60%',
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ ml: 1, mb: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>Primary Email</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    name="useDefaultEmail"
                    checked={formData.useDefaultEmail}
                    onChange={handleChange}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        borderRadius: '50%', // Border radius for the checkbox
                      },
                      '&.Mui-checked': {
                        color: '#535A6A', // Color when checked
                      },
                    }}
                  />
                }
                label={
                  <>
                    Use as default personal profile email: <span style={{ color: '#F99494' }}>{loginEmail}</span>
                  </>
                }
                sx={{
                  display: 'flex',
                  fontFamily: 'DM Sans, Arial, sans-serif',
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'DM Sans, Arial, sans-serif',
                    fontSize: '0.9rem',
                    fontWeight: 400,
                    color: '#535A6A',
                  },
                }}
              />
              <TextField
                name="primary_email"
                value={formData.useDefaultEmail ? loginEmail : formData.primary_email}
                onChange={(e) => !formData.useDefaultEmail && handleChange(e)}
                disabled={formData.useDefaultEmail}
                fullWidth
                error={!!errors.primary_email}
                helperText={errors.primary_email}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    height: "46px",
                    width: '60%',
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: 500,
                  fontFamily: 'DM Sans, Arial, sans-serif',
                  color: "#283246",
                  mt: 1,
                  display: 'flex',
                }}
              >
                Company Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel sx={{ fontSize: '0.9rem', display: 'flex', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>Country</InputLabel>
              <TextField  name="country"
                value={formData.country}
                onChange={handleChange}
                displayEmpty
                fullWidth
                error={!!errors.country}
                helperText={errors.country}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    minWidth: "237px",
                    height: "46px",
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel sx={{ fontSize: '0.9rem', display: 'flex', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>State</InputLabel>
              <TextField
                name="state"
                value={formData.state}
                onChange={handleChange}
                fullWidth
                error={!!errors.state}
                helperText={errors.state}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    minWidth: "237px",
                    height: "46px",
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel sx={{ fontSize: '0.9rem', display: 'flex', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>City</InputLabel>
              <TextField
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                error={!!errors.city}
                helperText={errors.city}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    maxWidth: "280px",
                    height: "46px",
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ fontSize: '0.9rem', display: 'flex', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>Street Address</InputLabel>
              <TextField
                name="street_address"
                value={formData.street_address}
                onChange={handleChange}
                fullWidth
                error={!!errors.street_address}
                helperText={errors.street_address}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    height: "46px",
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ fontSize: '0.9rem', display: 'flex', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>ZIP Code</InputLabel>
              <TextField
                name="zip_code"
                value={formData.zip_code}
                onChange={handleChange}
                fullWidth
                error={!!errors.zip_code}
                helperText={errors.zip_code}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    maxWidth: "280px",
                    height: "46px",
                    "& input": {
                      padding: "0.8rem",
                      height: "auto",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    fontFamily: 'DM Sans, Arial, sans-serif'
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#97A1B6',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="useForShipping"
                    checked={formData.useForShipping}
                    onChange={handleChange}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        borderRadius: '50%', // Border radius for the checkbox
                      },
                      '&.Mui-checked': {
                        color: '#535A6A', // Color when checked
                      },
                    }}
                  />
                }
                label="Use this address for shipping purposes as well"
                sx={{
                  display: 'flex',
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'DM Sans, Arial, sans-serif',
                    fontSize: '0.9rem',
                    fontWeight: 400,
                    color: '#535A6A',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex',mt:3 }}>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={handleContinue}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  borderRadius: "12px",
                  width: "200px",
                  height: 45,
                  textTransform:'none',
                  background: "linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)",
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
  );
};

export default ProfileSetup;
