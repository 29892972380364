import React from "react";
import { Stepper, Step, StepLabel, Box, styled } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";

const steps = ["", "", "", "", "", ""];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#F99494",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#F99494",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#535A6A",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const CustomStepIcon = ({ active, completed, icon }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: active ? "#F99494" : completed ? "#F99494" : "#535A6A",
      color: "#fff",
    }}
  >
    {completed ? <Check /> : icon}
  </Box>
);

const Sidebar = ({ activeStep, isMobile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "row" : "column",
        justifyContent: isMobile ? "center" : "flex-start",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        width: "100%",
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation={isMobile ? "horizontal" : "vertical"}
        connector={<CustomConnector />}
        sx={{
          width: isMobile ? "100%" : "auto",
          margin: isMobile ? "0 auto" : "1rem auto",
          // height: isMobile ? "auto" : "100vh", // Increased height for vertical orientation
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={index}
            active={index === activeStep}
            completed={index < activeStep}
            // sx={{
            //   height: 80, // Increased height for each step
            // }}
          >
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon
                  {...props}
                  active={index === activeStep}
                  completed={index < activeStep}
                />
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Sidebar;
