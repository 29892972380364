import React, { useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import axonslabsidenav from "../../assets/image/axonslabsidenav.svg";
import Sidebar from "./Sidebar";
import ProfileSetup from "../ProfileSetup/index";
import { SelectDevice } from "../SelectDevice/index";
import Subscription from "../Subscription/index";
import OrderSummary from "../OrderSummary/index";
import Payment from "../Payment/index";
import OrderConfirmation from "../OrderConfirmation/index";

const BusinessProfile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return <ProfileSetup setActiveStep={setActiveStep} onNext={() => setCurrentStep(1)} />;
      case 1:
        return <SelectDevice setActiveStep={setActiveStep} onNext={() => setCurrentStep(2)} onBack={() => setCurrentStep(0)} />;
      case 2:
        return <Subscription setActiveStep={setActiveStep} onNext={() => setCurrentStep(3)} onBack={() => setCurrentStep(1)} />;
      case 3:
        return <OrderSummary setActiveStep={setActiveStep} onNext={() => setCurrentStep(4)} onBack={() => setCurrentStep(2)} />;
      case 4:
        return <Payment setActiveStep={setActiveStep} onNext={() => setCurrentStep(5)} onBack={() => setCurrentStep(3)} />;
      case 5:
        return <OrderConfirmation setActiveStep={setActiveStep} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Grid container sx={{ flex: 1 }}>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "flex-start",
            padding: isMobile ? "0.5rem" : "2rem",
            order: isMobile ? 0 : 1,
          }}
        >
          {!isMobile && (
            <Box sx={{ marginBottom: "2rem", width: "100%" }}>
              <img src={axonslabsidenav} style={{ width: "100%" }} alt="sidenav" />
            </Box>
          )}
          <Sidebar activeStep={activeStep} isMobile={isMobile} />
        </Grid>
        <Grid item xs={12} sm={10} sx={{ overflow: "auto", width: "100%", order: isMobile ? 1 : 2 }}>
          {renderCurrentStep()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessProfile;
