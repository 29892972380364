import React, { useState } from "react";
import LoginOtp from "../../components/LoginOtp";
import Login from "../../components/Login";

export const Signin = () => {
  const [emailAuth, setEmailAuth] = useState(false);
  return (
    <>{emailAuth ? <LoginOtp /> : <Login setEmailAuth={setEmailAuth} />}</>
  );
};
