// utils/countdownUtils.js
export const calculateTimeLeft = (timestamp) => {
    const now = new Date().getTime(); // Current time in milliseconds
    const endTime = new Date(timestamp * 1000).getTime(); // Convert seconds to milliseconds for comparison
  
    const timeDifference = endTime - now;
  
    if (timeDifference <= 0) {
      return { minutes: 0, seconds: 0, expired: true };
    }
  
    let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
    // Ensure minutes and seconds are within valid ranges
    minutes = Math.max(0, minutes);
    seconds = Math.max(0, seconds);
  
    return { minutes, seconds, expired: false };
  };
  