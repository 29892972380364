import React, { useState, useEffect, useContext } from "react";
import {ArrowBack as ArrowBackIcon} from '@mui/icons-material';
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import {
    Button,
    Typography,
    Box,
    TextField,
    Paper,
    CircularProgress,
    Grid,
    Modal,
    Backdrop,
    LinearProgress,
} from "@mui/material";
import DataContext from "../../DataContext";
import { useQuery } from "react-query";
import { PostCheckout } from "../../api/querise";
import mastercard from "../../assets/icons/mastercard.svg"
import visacard from "../../assets/icons/visa.svg"
import jcbcard from "../../assets/icons/jcb.svg"
import expresscard from "../../assets/icons/aexpress.svg"
import paymentAnimation from "../../assets/image/paymentprocess.gif"


const stripePromise = loadStripe("pk_test_51PTMBPP2lRiLb8rerMMomjxITWNJjPSfIRDUBrDqvvNHTcIpUB8Bqm9lFP9Ywx9aNKZ4oqhPF732Ci9sOo5QuGyE00QNmTA96f");
const typographyStyle = { fontsize: "12px", fontWeight: 500, color: "#535A6A",fontFamily:"DM Sans, Arial, sans-serif",display:'flex' }
const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#535A6A',
        fontFamily: 'DM Sans, Arial, sans-serif',
        '::placeholder': { color: '#aab7c4' },
        padding: '10px 14px',
        backgroundColor: '#ffffff',
        borderRadius: '0.75rem',
        boxSizing: 'border-box',
        marginTop: '1rem',
        marginBottom: '1rem',
      },
      invalid: { color: '#fa755a' }
    }
  };

const AddCardForm = ({ setActiveStep, onNext, onBack }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const { clientSecret, uniqueId } = useContext(DataContext);
    const [stripeIntent, setStripeIntent] = useState('');
    const [shouldPost, setShouldPost] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [paymentIntentKey,setPaymentIntent] = useState('')


    const { data, apiError } = useQuery(["users", stripeIntent, uniqueId], PostCheckout, {
        enabled: shouldPost,
        retry: false,
    });

    useEffect(() => {
        if (stripeIntent) {
            setShouldPost(true);
            setLoading(true)
        }
    }, [stripeIntent]);

    useEffect(() => {
        if (data) {
            setLoading(false);
            setShouldPost(false);
            setIsSubmitted(false);
            if(data?.status==='incomplete'){
              setLoading(true);
                setPaymentIntent(data?.next_action?.client_secret)
            } else {
                setActiveStep(5)
                onNext()
            }
        } else if (apiError) {
            setShouldPost(false);
        }

        // eslint-disable-next-line
    }, [data, apiError]);

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
          setLoading(false)
            return;
        }

        try {
            const cardNumberElement = elements.getElement(CardNumberElement);
            const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: {
                        name: name,
                        email: email,
                    },
                },
            });

            if (error) {
              setLoading(false)
                throw new Error(error.message);
            }

            if (setupIntent.status === 'succeeded') {
                setError(null);
            } else if (setupIntent.status === 'requires_action' || setupIntent.status === 'requires_confirmation') {
                // Handle additional actions required
            } else {
                throw new Error(`Unexpected status: ${setupIntent.status}`);
            }

            setStripeIntent(setupIntent);
        } catch (error) {
            console.error("Error:", error);
            setError(error.message);
        }
    };

    useEffect(() => {
        if (isSubmitted) {
            handleSubmit();
        }
        // eslint-disable-next-line
    }, [clientSecret]);

    const nextAction = async () => {
        const { error: nextActionError, paymentIntent } = await stripe.handleNextAction({
            clientSecret: paymentIntentKey
        });

        if (nextActionError) {
          setLoading(false)
        } else if (paymentIntent.status === 'succeeded') {
          setLoading(false) 
          setActiveStep(5)
          onNext()
        }
    };

    const handleBack = () => {
      setActiveStep(3);
      onBack()
    };

    useEffect(() => {
        if (paymentIntentKey) {
            nextAction();
        }
        // eslint-disable-next-line
    }, [paymentIntentKey]);

    return (
        <Paper sx={{ p: 1,borderRadius: '0.938rem', boxShadow: 'none', minHeight: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           
           <Box sx={{ width: '80%' }}>
        <Typography sx={{ color: "#283246", fontSize: 26, fontWeight: 700 ,display:'flex'}}>
          Enter your payment details 
        </Typography>
                <Box sx={{ textAlign: 'start', mb: 2,mt:2,width:'70%'}}>
                    <Typography sx={{ fontsize: "12px", fontWeight: 500, color: "#535A6A", fontFamily: "DM Sans, Arial, sans-serif" }}>Pay securely using your credit or debit card.</Typography>
                    <Box sx={{ p: 0.5 }}>
                        <img src={mastercard} width={65} height={45} alt="Mastercard" />
                        <img src={visacard} width={65} height={45} alt="Visa" />
                        <img src={jcbcard} width={65} height={45} alt="JCB" />
                        <img src={expresscard} width={65} height={45} alt="American Express" />
                    </Box>
                </Box>
                <Box component="form" onSubmit={(e) => { setIsSubmitted(true); handleSubmit(e); }} noValidate sx={{ mt: 1,width:'70%' }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={typographyStyle}>Cardholder Name</Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            placeholder="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "12px",
                                    "& input": { padding: "0.8rem" }
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={typographyStyle}>Email</Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            placeholder="Email"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "12px",
                                    "& input": { padding: "0.8rem" }
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={typographyStyle}>Card Number</Typography>
                        <Box sx={{
                            '& .StripeElement': {
                                ...cardElementOptions.style.base,
                                border: '1px solid #ced4da'
                            },
                            '& .StripeElement--focus': {
                                // borderColor: '#80bdff',
                                // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                            },
                            '& .StripeElement--invalid': {
                                borderColor: '#fa755a',
                            },
                        }}>
                            <CardNumberElement options={cardElementOptions} />
                        </Box>
                    </Box>
                    <Box container sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        <Box item sx={{ flex: 1 }}>
                            <Typography sx={typographyStyle}>Expiration</Typography>
                            <Box sx={{
                                '& .StripeElement': {
                                    ...cardElementOptions.style.base,
                                    border: '1px solid #ced4da'
                                },
                                '& .StripeElement--focus': {
                                    // borderColor: '#80bdff',
                                    // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                                },
                                '& .StripeElement--invalid': {
                                    borderColor: '#fa755a',
                                },
                            }}>
                                <CardExpiryElement options={cardElementOptions} />
                            </Box>
                        </Box>
                        <Box item sx={{ flex: 1 }}>
                            <Typography sx={typographyStyle}>CVC</Typography>
                            <Box sx={{
                                '& .StripeElement': {
                                    ...cardElementOptions.style.base,
                                    border: '1px solid #ced4da'
                                },
                                '& .StripeElement--focus': {
                                    // borderColor: '#80bdff',
                                    // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                                },
                                '& .StripeElement--invalid': {
                                    borderColor: '#fa755a',
                                },
                            }}>
                                <CardCvcElement options={cardElementOptions} />
                            </Box>
                        </Box>
                    </Box>
                    {error && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        </Box>
                    )}
                      {loading && <Typography sx={{fontFamily:"DM Sans, Arial, sans-serif",fontSize:'1rem',color:"#EF5350",width:'70%',mt:2}}>Payment is processing. Please do not refresh the page or click the back button.</Typography>}
                   <Grid item xs={12} sx={{ display: 'flex', mt:6 }}>
  <Button sx={{
    fontSize: 14,
    fontWeight: 400,
    borderRadius: "12px",
    width: "200px",
    height: 45,
    textTransform: 'none',
    mr: 5,
    backgroundColor: "#F8F8F8",
    color: "#535A6A",
    border: "1px solid #DDDEE1",
  }} startIcon={<ArrowBackIcon />} onClick={handleBack} disabled={loading}>
    Back
  </Button>
  
  <Button
    type="submit"
    fullWidth
    variant="contained"
    sx={{
        width: "200px",
        borderRadius: '0.75rem',
      height: '45px',
      textTransform: 'none',
      position: 'relative',
      background: "linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)",
      '&:disabled': {
        backgroundColor: '#d3d3d3', // Set a slightly darker gray for the disabled state
        color: 'rgba(0, 0, 0, 0.5)', // Adjust text color if needed
      },
      '&:hover': {
        backgroundColor: loading ? '#ec971f' : 'primary.dark',
      },
     
    }}
    disabled={loading}
  >
    {loading ? (
      <CircularProgress
        size={24}
        sx={{
          color: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    ) : (
      'Pay'
    )}
  </Button>
        {/* Modal for loading state */}
        <Modal
        open={loading}
        aria-labelledby="loading-modal"
        aria-describedby="loading-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, // Dimmed background
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <img src={paymentAnimation} style={{ height: '150px', width: '150px', margin: '0 auto' }}/> 
          <Box sx={{ width: '100%', mt: 3 }}>
            <LinearProgress sx={{
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#F97E7E',
        },
      }} />
          </Box>
        </Box>
      </Modal>
</Grid>
                </Box>
            </Box>
        </Paper>
    );
};

const Payment = ({ setActiveStep, onNext, onBack }) => (
    <Elements stripe={stripePromise}>
        <AddCardForm setActiveStep={setActiveStep} onNext={onNext} onBack={onBack} />
    </Elements>
);

export default Payment;
