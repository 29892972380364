import React, { useContext } from 'react';
import { Box, Typography, Button, Grid, Divider } from '@mui/material';
import DataContext from '../../DataContext';
import { plans } from '../Subscription';
import orderConfirm from "../../assets/image/orderconfirm.svg"

const OrderConfirmation = () => {

  const { selectedDevices,productPrice, selectedPlan, value ,planPrice} = useContext(DataContext); 

  function getPlanType(value, selectedPlan) {
    const planType = value === 0 ? "monthly" : "yearly";
    const selectedPlanDetails = plans[planType]?.find(plan => plan.name === selectedPlan);
    return selectedPlanDetails ? selectedPlanDetails?.name : null;
  }

  function getMatchingPrice(selectedPlanDetails, planPrice) {
    const matchedPlan = planPrice?.yearly?.find(plan => plan.name === selectedPlanDetails);  
    return matchedPlan ? matchedPlan.price : null;
  }

  const calculateTotal = () => {
    return (productPrice?.AxonsMicro[selectedDevices?.AxonsMicro]?.discountedPrice || 0) + (productPrice.AxonsMicroPro[selectedDevices.AxonsMicroPro]?.discountedPrice || 0) + 500 
  };

  
  const orderItems = [
    { description: 'Axons Basic License', detail: '', price: 500 },
    { description: 'Axons Micro', detail: `${selectedDevices.AxonsMicro} Devices`, price: productPrice.AxonsMicro[selectedDevices.AxonsMicro]?.discountedPrice },
    { description: 'Axons Micro Pro', detail: `${selectedDevices.AxonsMicroPro} Devices`, price: productPrice.AxonsMicroPro[selectedDevices.AxonsMicroPro]?.discountedPrice },
    { description: `Subscription (${value===0?'Monthly':'Yearly'})`, detail: getPlanType(value, selectedPlan), price: getMatchingPrice(selectedPlan,planPrice),}
  ];



  return (
    <Box p={3} bgcolor="#f9fafc" minHeight="90vh">
      <Box mb={2}>
        <Typography  sx={{
            color: "#283246",
            fontSize: "1.625rem",
            fontWeight: 700,
            fontFamily: "DM Sans, Arial, sans-serif",
            display: 'flex',
          }}>Order Confirmation</Typography>
      </Box>
      <Box mb={3} display="flex" alignItems="center" bgcolor="#e8f5e9" p={2} borderRadius="11px" sx={{width:'70%'}}>
      <img src={orderConfirm} alt="order-confirm"/>
        <Box ml={2}>
          <Typography  sx={{
            color: "#283246",
            fontSize: "1rem",
            fontWeight: 600,
            fontFamily: "DM Sans, Arial, sans-serif",
            display: 'flex',
          }}>Your order is confirmed</Typography>
          <Typography sx={{
            color: "#283246",
            fontSize: "0.8rem",
            fontWeight: 400,
            fontFamily: "DM Sans, Arial, sans-serif",
            display: 'flex',
            textAlign:'left'
          }}>Thank you! Your order has been successfully placed. A detailed receipt has been sent to your registered email.<br/> Welcome to the next level of IoT innovation with Axons Lab.</Typography>
        </Box>
      </Box>
      <Box sx={{p: 2, borderRadius: '17px', border: '1px solid #DDDEE1',width:'80%' }}>
      {orderItems.map((order)=>(
          <>
          <Grid container spacing={0.5} sx={{p: 2, display: 'flex', justifyContent: 'space-between'}}>
          <Grid item xs={7} sx={{fontFamily: "DM Sans, Arial, sans-serif",fontSize:'1rem',color:'#535A6A',fontWeight:500,textAlign:'start',}}>{order.description}</Grid>
          <Grid item xs={3} sx={{display:'flex'}}>
            <Typography sx={{fontFamily: "DM Sans, Arial, sans-serif",fontSize:'1rem',color:'#535A6A',fontWeight:500,textAlign:'start',}}>{order.detail}</Typography>
          </Grid>
          <Grid item xs={2} sx={{fontFamily: "DM Sans, Arial, sans-serif",fontSize:'1rem',color:'#283246',fontWeight:500,textAlign:'end'}}>$ {order.price}</Grid>
        </Grid>
            <Divider variant="middle" />
        </>
        ))}
            <Grid container spacing={0.5} sx={{p: 2, display: 'flex', justifyContent: 'space-between'}}>
              <Grid item xs={10} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'start' }}>Total
                <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#535A6A', fontWeight: 500 }}>See your total (including taxes)</Typography>
                
              </Grid>
              <Grid item xs={2} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'end' }}>${calculateTotal()}</Grid>
            </Grid>
            <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '0.8rem', color: '#535A6A', fontWeight: 600 }}>Note: The plan subscription amount will be automatically deducted when the device is connected to the vehicle.</Typography>
          </Box>                               
          <Button sx={{textTransform:'none',fontWeight:500,color:'rgba(40, 50, 70, 1)',border:'1px solid #DDDEE1',mt:2,display:'flex',borderRadius:'0.75rem',width:'25%',bgcolor:'rgba(221, 222, 225, 1)',opacity:'100%'}} onClick={()=>window.location.href='https://dashboard.axonslab.io'}>Back to Website</Button>
    </Box>
  );
};

export default OrderConfirmation;
