import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CircularProgress, TextField } from "@mui/material";
import { loginOTP, loginUser } from "../api/querise";
import { useQuery } from "react-query";
import DataContext from "../DataContext";
import axonslablogin from "../assets/image/axonslablogin.svg";
import axonslogin from "../assets/image/axonslogin.svg";
import axonslogin2 from "../assets/image/axonslogin2.svg";
import axonslogin3 from "../assets/image/axonslogin3.svg";
import axonslogin4 from "../assets/image/axonslogin4.svg";
import axonslogin5 from "../assets/image/axonslogin5.svg";
import axonslogin6 from "../assets/image/axonslogin6.svg";
import { calculateTimeLeft } from "./countdownUtils";
import { useSnackbar } from "../hooks/Snackbar";


const images = [
  axonslogin,
  axonslogin2,
  axonslogin3,
  axonslogin4,
  axonslogin5,
  axonslogin6,
];

const LoginOtp = () => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [currentSlide, setCurrentSlide] = useState(0);
  const { setUniqueId,newOtp,otpResend ,setOtpResend,setNewOtp,setFormData,setSelectedDevices,setSelectedPlan,setValue} = useContext(DataContext);
  const [shouldPost, setShouldPost] = useState(false);
  const [shouldPostResent, setShouldPostResent] = useState(false);
  const loginEmail = localStorage.getItem("email");
  const { minutes, seconds, expired } = calculateTimeLeft(otpResend);
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();


  const formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;


  const { data, error ,isLoading} = useQuery(
    ["users", loginEmail, otp.join("")],
    loginOTP,
    {
      enabled: shouldPost,
      retry: false,
    }
  );


  const { data:responceLogin, error:errorLogin} = useQuery(["users", loginEmail], loginUser, {
    enabled: shouldPostResent,
  });

  useEffect(() => {
    if (data) {
      localStorage.setItem('authToken', data.id)
      setFormData({ company_name: data?.data?.business_name,
      primary_email:data?.data?.business_email,
      country: data?.data?.address?.billing_address?.country,
      state: data?.data?.address?.billing_address?.state,
      city: data?.data?.address?.billing_address?.city,
      street_address: data?.data?.address?.billing_address?.street_address,
      zip_code: data?.data?.address?.billing_address?.zip_code,
      useDefaultEmail: false,
      useForShipping: false,})
      const selectedDevices = {
        AxonsMicro: 0,
        AxonsMicroPro: 0,
      };
    
      const products = data?.data?.cart?.products || [];
      const plan = data?.data?.cart?.plan || [];
    
    
      products.forEach(product => {
        if (product.name === 'axons_micro') {
          selectedDevices.AxonsMicro += product.quantity;
        } else if (product.name === 'axons_micro_pro') {
          selectedDevices.AxonsMicroPro += product.quantity;
        }
      });

    
      if ((Array.isArray(plan) && plan.length) || (typeof plan === 'object' && plan !== null && !Array.isArray(plan))) {
        setSelectedPlan(plan.plan_type)
        setValue(plan.billing_cycle)
    }else{
      setSelectedPlan('Premium')
      setValue(1)
    }

      if(products.length){
        setSelectedDevices(selectedDevices);
      }else{
        setSelectedDevices({ 
          AxonsMicro: 100,
          AxonsMicroPro: 100,})
      }
      setShouldPost(false);
      setUniqueId(data?.data.id);
      setOtpResend(data?.data?.otp_interval_time)
      navigate("/business-profile");
    } else if (error) {
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");
      setShouldPost(false);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(()=>{
    if(responceLogin){
      setShouldPostResent(false)
      setOtpResend(responceLogin?.data?.otp_interval_time)
      setNewOtp(responceLogin?.data?.otp)
    }else if(errorLogin){
      showSnackbar((errorLogin?.response?.data?.message || error?.message) ||  'An error occurred', "error");

      setShouldPostResent(false)
    }
    // eslint-disable-next-line
  },[responceLogin,errorLogin])


  const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };


  const handleSubmit = () => {
    setShouldPost(false);
    setShouldPost(true);
  };

  const handleResendOtp = async ()=>{
    setShouldPostResent(false);
    setShouldPostResent(true);
  }

  const extendedImages = [...images, images[0]];

  const handleChangeCarousal = (index) => {
    // If we are at the duplicate slide (last slide), reset the position to the first slide
    if (index === images.length) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(index);
    }
  };


  useEffect(() => {
    if (currentSlide === 0) {
      const carouselElement = document.querySelector('.carousel');
      carouselElement.style.transition = 'none';
      carouselElement.style.transform = 'translate3d(0px, 0px, 0px)';

      setTimeout(() => {
        carouselElement.style.transition = '';
      }, 0);
    }
  }, [currentSlide]);




  return (
    <Grid
    container
    sx={{ bgcolor: "#FFFFFF", padding: "1rem" }}
    justifyContent="center"
    alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
        }}
      >
        <Box>
          <img src={axonslablogin} alt="axonslablogin" />
        </Box>
        <Card
           sx={{
            width: { xs: "100%", sm: 440 },
            minHeight: 320,
            borderRadius: "25px",
            mt: 7,
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                fontFamily: "DM Sans, Arial, sans-serif",
                color: "#535A6A",
              }}
            >
              Confirm Your Email
            </Typography>
            <Typography
             sx={{
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "DM Sans, Arial, sans-serif",
              color: "#535A6A",
            }}
            >
              We sent you a 6-digit code to {loginEmail}
              please enter it below.Can’t find it? Check your spam folder.<span  style={{
      fontWeight: 800,
      fontSize: '1rem',
      letterSpacing: '0.3rem', // Adds spacing between characters
      padding: '0.2rem 0.5rem', // Adds padding around the text
      backgroundColor: '#f0f0f0', // Adds a subtle background color
      color: '#333', // Change text color to make it more readable
      borderRadius: '5px', // Gives a slight rounded edge to the background
    }}>{" "}{String(newOtp).split('').join(' ')}</span>
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" gap="10px" sx={{p:'0.5rem'}}>
            {otp.map((digit, index) => (
        <TextField
          key={index}
          id={`otp-input-${index}`}
          value={digit}
          onChange={(e) => handleChangeOtp(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          variant="outlined"
          type={'text'}
          sx={{
            width: '20px',
            height: '20px',
            borderRadius: '12px',
            border: '1px solid #97A1B6',
            textAlign: 'center',
            padding: '10px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
            '& .MuiInputBase-input': {
              textAlign: 'center',
              padding: '0',
              fontSize: '16px',
            },
          }}    
        />
      ))}
    </Box>
    <Box sx={{display:'flex',alignItems: 'center'}}>
            <Button
               sx={{
                fontSize: '1rem',
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                color: expired ? '#535A6A' : 'gray',
                backgroundColor: expired
        ? 'linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)'
        : 'linear-gradient(to right, #ccc, #ddd)',
      borderRadius: '30px',
      textTransform: 'none',
      transition: 'background-color 0.4s ease, box-shadow 0.4s ease',
      '&:hover': {
          border:expired?'1px solid gray':'none',
      },
      '&:disabled': {
        cursor: 'not-allowed',
      },
      ml: { xs: 4, sm: 10, md: expired?15:10 },
              }}
              disabled={!expired}
              onClick={handleResendOtp}
            >
             {expired ? 'Send a new code' : `Resend code available in`}
            </Button>


            {formattedTime !== '0:00' && (
  <Typography sx={{  color: "#283246"}}>
    {formattedTime}
  </Typography>
)}
</Box>
            <Button
              sx={{
                mt:1,
                borderRadius: "12px",
                width: "80%",
                height: 55,
                color:'white',
                textTransform:'none',
                background:
                  "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                },
              }}
              onClick={handleSubmit}
            >
               {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                'Verify OTP'
              )}
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
          mt: { xs: 5, md: 2 },
        }}
         
      >
        <Card
          sx={{
            width: '90%',
            borderRadius: "15px",
            marginLeft:'4rem'
          
          }}
        >
          <Carousel
           selectedItem={currentSlide}
           onChange={handleChangeCarousal}
            autoPlay
            infiniteLoop
            interval={2000}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
          >
             {extendedImages.map((URL, index) => (
        <div className="slide" key={index}>
          <img alt="carousel_image" src={URL} />
        </div>
      ))}
          </Carousel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginOtp;
