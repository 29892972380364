import React, { useContext, useState, useEffect } from 'react';
import { Divider, Box, Grid, Typography, Button,
  Radio, Modal,TextField, MenuItem,
  Select, IconButton, InputLabel, RadioGroup, FormControlLabel, Paper
} from '@mui/material';
import {
  ArrowForward as ArrowForwardIcon, ArrowBack as ArrowBackIcon,
  Add as AddIcon,Close as CloseIcon
} from '@mui/icons-material';
import DataContext from "../../DataContext";
import { postCompanyCart } from '../../api/querise';
import { useQuery, useQueryClient } from 'react-query';
import { plans } from '../Subscription';
import editicon from "../../assets/icons/editicon.svg"
import { useSnackbar } from '../../hooks/Snackbar';

const styles = {
  container: { height: '90vh', p: 3 },
  stepTitle: { color: '#F99494', fontSize: 18, fontWeight: 400 },
  mainTitle: { color: '#283246', fontSize: 26, fontWeight: 700 },
  orderSummaryBox: { p: 2, borderRadius: '17px', border: '1px solid #DDDEE1', width: '85%' },
  productRow: { p: 2, display: 'flex', justifyContent: 'space-between' },
  totalRow: { p: 2, fontSize: 12, fontWeight: 500 },
  addressBox: { mt: 2, p: 2, borderRadius: '17px', border: '1px solid #DDDEE1', width: '85%' },
  addressRow: { p: 2 },
  addressButton: { fontSize: 12,textTransform:'none', fontWeight: 500, borderRadius: '12px', width: 120, height: 32, background: '#F8F8F8', color: '#535A6A', border: '1px solid #DDDEE1', padding: '8px 12px' },
  actionButton: { fontSize: 14,textTransform:'none', fontWeight: 400, marginTop: 2, borderRadius: '12px', width: 200, height: 45, background: '#F8F8F8', color: '#535A6A', display: 'flex', border: '1px solid #DDDEE1' },
  continueButton: { fontSize: 14, fontWeight: 400, marginTop: 2, borderRadius: '12px', width: 150, height: 45, background: 'linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)' },
  modalStyle: {
    position: "absolute",
    padding: '1rem',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "1.5rem",
    minHeight: "30%",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
  }
};






export default function OrderSummary({ setActiveStep, onNext, onBack }) {
  const { selectedDevices, setSelectedDevices, formData,planPrice, uniqueId, productKey, selectedPlan, value, setClientSecret ,productPrice} = useContext(DataContext);


  const [selectedValue, setSelectedValue] = useState(formData && formData.useForShipping ? { ...formData, id: 'initial' } : null);
  const [modalOpen, setModalOpen] = useState(false);
  const [quantityModelOpen, setQuantityModelOpen] = useState(false);
  const [modalDevice, setModalDevice] = useState('');
  const [newAddress, setNewAddress] = useState({ country: '', state: '', city: '', zip_code: '', street_address: '' });
  const [currentEditAddress, setCurrentEditAddress] = useState(null);
  const [validationError, setValidationError] = useState('');
  const [addresses, setAddresses] = useState(
    formData && formData.useForShipping ? [{ ...formData, id: 'initial' }] : []
  );
    const [shouldPost, setShouldPost] = useState(false);
  const [quantity, setQuantity] = useState(100);
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();


  function getMatchingPrice(selectedPlanDetails, planPrice) {
    const matchedPlan = planPrice?.yearly?.find(plan => plan.name === selectedPlanDetails);  
    return matchedPlan ? matchedPlan.price : null;
  }


  useEffect(() => {
    if (formData &&formData.useForShipping&& formData.street_address && formData.city && formData.state && formData.country && formData.zip_code) {
      setAddresses([{ id: 'initial', ...formData }]);
      setSelectedValue({ id: 'initial', ...formData });
    }
  }, [formData]);

  const filteredPlan = productKey?.plans.find(plan => {
    const period = value === 0 ? 'Per Month' : 'Per Year';
    return plan.name.includes(period) && plan.name.includes(selectedPlan);
  });

  function getPlanType(value, selectedPlan) {
    const planType = value === 0 ? "monthly" : "yearly";
    const selectedPlanDetails = plans[planType]?.find(plan => plan.name === selectedPlan);
    console.log(selectedPlanDetails,'selectedplandetails')
    return selectedPlanDetails ? selectedPlanDetails?.name : null;
  }




  const datapayload = () => {
    const selectedShippingAddress = selectedValue
    const payload = {
      shipping_address: selectedShippingAddress,
      cart: {
        products: [
          { id: productKey.products[0]?.id, quantity: selectedDevices.AxonsMicro, name: 'axons_micro' },
          { id: productKey.products[1]?.id, quantity: selectedDevices.AxonsMicroPro, name: 'axons_micro_pro' }
        ],
        licence_fees: { id: productKey?.licence_fees?.id, quantity: 1 },
        plan: {
          id: filteredPlan?.id, quantity: selectedDevices?.AxonsMicro + selectedDevices.AxonsMicroPro, plan_type:selectedPlan,billing_cycle:value
        }
      }
    };
    return payload;
  };

  const { data, error } = useQuery(["users", datapayload(), uniqueId], postCompanyCart, {
    enabled: shouldPost,
    retry: false,
  });


  useEffect(() => {
    if (data) {
      setClientSecret(data.client_secret);
      setShouldPost(false);
      setActiveStep(4);
      onNext()
      queryClient.removeQueries(["users", datapayload(), uniqueId]);
    } else if (error) {
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");
      setShouldPost(false);
    }

    // eslint-disable-next-line
  }, [data, error]);


  const handleContinue = () => {
    if (!selectedValue) {
      setValidationError('Please add a delivery address.'); // Set error message if no address is selected
      return;
    }
    setShouldPost(false);
    setShouldPost(true);
  };

  const handleBack = () => {
    setActiveStep(2);
    onBack()
  };

  const handleModalOpen = (address) => {
    if (address) {
      setCurrentEditAddress(address);
      setNewAddress({ ...address });
    } else {
      setCurrentEditAddress(null);
      setNewAddress({ country: '', state: '', city: '', zip_code: '', street_address: '' });
    }
    setModalOpen(true);
  };

  const handleModalOpenQuantity = (device) => {
    setModalDevice(device);
    setQuantityModelOpen(true);
  };

  const handleModalCloseQuantity = () => {
    setQuantityModelOpen(false);
    setQuantity(100)
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalDevice('');
    setCurrentEditAddress(null);  // Reset the edit state
  };

  const handleAddressSelection = (event) => {
    const selectedAddressId = event.target.value;
    const selectedAddress = addresses.find(address => address.id === selectedAddressId);
    setSelectedValue(selectedAddress);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
   
    setNewAddress(prevState => ({ ...prevState, [name]: value }));
  };



  const saveAddress = () => {
    if (currentEditAddress) {
      setAddresses(addresses.map(address => address?.id === currentEditAddress?.id ? { ...currentEditAddress, ...newAddress } : address));
    } else {
      const newAddr = { ...newAddress, id: `${addresses.length + 1}` };
      setAddresses([...addresses, newAddr]);
      if (addresses.length === 0) {
        setSelectedValue(newAddr); // Preselect the first added address
      }
    }
    handleModalClose();
  };



  const calculateTotal = () => {
    return (productPrice?.AxonsMicro[selectedDevices?.AxonsMicro]?.discountedPrice || 0) + (productPrice?.AxonsMicroPro[selectedDevices?.AxonsMicroPro]?.discountedPrice || 0) + 500 
  };


  const handleQuantityChange = (event) => {
    const newQuantity = event.target.value;
    setQuantity(newQuantity);
  };


  const handleUpdate = (device) => {
       setSelectedDevices(prevState => ({ ...prevState, [device]: quantity }));
      setQuantityModelOpen(false);
      setQuantity(100)
  };


  return (
    <>
       <Paper sx={{ p: 2, borderRadius: "12px",boxShadow:'none' }}>
          <Typography sx={{
            color: "#283246",
            fontSize: "1.625rem",
            fontWeight: 700,
            fontFamily: "DM Sans, Arial, sans-serif",
            mb: 2,
            mt:2,
            display: 'flex',
          }}>Order Summary</Typography>
          <Box sx={styles.orderSummaryBox}>
            <Grid container spacing={0.5} sx={styles.productRow}>
              <Grid item xs={10} sx={{ fontFamily: "DM Sans, Arial, sans-serif", textAlign: 'start', fontSize: '1rem', color: '#535A6A', fontWeight: 500 }}>Axons Basic License</Grid>
              <Grid item xs={2} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', textAlign: 'end', color: '#283246', fontWeight: 500 }}>$500</Grid>
            </Grid>
            <Divider variant="middle" />
            {selectedDevices.AxonsMicro ? (
              <>
 <Grid container spacing={0.5} sx={styles.productRow}>
 <Grid item xs={7} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#535A6A', fontWeight: 500, textAlign: 'start', }}>Axons Micro</Grid>
 <Grid item xs={3} sx={{ display: 'flex' }}>
   <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif",mr:1, fontSize: '1rem', color: '#535A6A', fontWeight: 500, textAlign: 'start' }}>{selectedDevices?.AxonsMicro || 0} Devices</Typography>
   <IconButton size="small" onClick={() => handleModalOpenQuantity('AxonsMicro')}  
           style={{
            width: '50.3px',
            height: '23px',
            borderRadius: '10px',
            border: "0.65px solid rgba(221, 222, 225, 1)",
            p:1,
          }}
          >
            <img src={editicon} alt="Edit" />
          </IconButton>
 </Grid>
 <Grid item xs={2} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'end' }}>${productPrice?.AxonsMicro[selectedDevices.AxonsMicro]?.discountedPrice || 0}</Grid>
</Grid>
<Divider variant="middle" />
</>):''}
            {selectedDevices.AxonsMicroPro? 
            <>
             <Grid container spacing={0.5} sx={styles.productRow}>
             <Grid item xs={7} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#535A6A', fontWeight: 500, textAlign: 'start' }}>Axons Micro Pro</Grid>
             <Grid item xs={3} sx={{ display: 'flex' }}>
               <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif",mr:1, fontSize: '1rem', color: '#535A6A', fontWeight: 500, textAlign: 'start' }}>{selectedDevices.AxonsMicroPro || 0} Devices</Typography>
               <IconButton size="small" onClick={() => handleModalOpenQuantity('AxonsMicro')}  
           style={{
            width: '50.3px',
            height: '23px',
            borderRadius: '10px',
            border: "0.65px solid rgba(221, 222, 225, 1)",
            p:1,
          
          }}
          >
            <img src={editicon} alt="Edit" />
          </IconButton>
             </Grid>
             <Grid item xs={2} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'end' }}>${productPrice?.AxonsMicroPro[selectedDevices.AxonsMicroPro]?.discountedPrice || 0}</Grid>
           </Grid>
             <Divider variant="middle" />
             </>
            :''}
           
          
            <Grid container spacing={0.5} sx={styles.productRow}>
              <Grid item xs={7} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#535A6A', fontWeight: 500, textAlign: 'start', }}>Subscription ({value===0?'Monthly':'Yearly'})</Grid>
              <Grid item xs={3} sx={{ display: 'flex' }}>
                <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#535A6A', fontWeight: 500, textAlign: 'start', }}>{getPlanType(value, selectedPlan)}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'end' }}>${getMatchingPrice(selectedPlan,planPrice)}</Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid container spacing={0.5} sx={styles.productRow}>
              <Grid item xs={10} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'start' }}>Total
                <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#535A6A', fontWeight: 500 }}>See your total (including taxes)</Typography>
              </Grid>
              <Grid item xs={2} sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '1rem', color: '#283246', fontWeight: 500, textAlign: 'end' }}>${calculateTotal()}</Grid>
            </Grid>
            <Typography sx={{ fontFamily: "DM Sans, Arial, sans-serif", fontSize: '0.9rem', color: '#535A6A', fontWeight: 600 }}>Note: The plan subscription amount will be automatically deducted when the device is connected to the vehicle.</Typography>

          </Box>

          <Box sx={styles.addressBox}>
            <Typography sx={{ fontSize: 15,fontFamily: "DM Sans, Arial, sans-serif", fontWeight: 500, display: 'flex',color:'#535A6A' }}>Choose Your Delivery Address</Typography>
            <RadioGroup value={selectedValue?.id || ''} onChange={handleAddressSelection}>
      {addresses.map((address) => (
        <Grid container spacing={0.5} sx={{ marginBottom: 1 }} key={address.id}>
          <Grid item xs={10}>
            <FormControlLabel
              value={address.id}
              control={<Radio sx={{ '&.Mui-checked': { color: '#535A6A' } }} />}
              label={
                <Typography sx={{ color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif', fontSize: '0.8rem', fontWeight: 400 }}>
                  {`${address.street_address}, ${address.city}, ${address.state} ${address.zip_code}, ${address.country}`}
                </Typography>
              }
              sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
            />
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {selectedValue?.id === address.id && (
      <Button sx={{ fontSize: 14, fontWeight: 400,textTransform:'none', borderRadius: '12px', width: 200, height: 45, background: '#F8F8F8', color: '#535A6A', display: 'flex', border: '1px solid #DDDEE1' }} onClick={() => handleModalOpen(address)}>Edit address</Button>
          )}
          </Grid>
        </Grid>
      ))}
    </RadioGroup>
            <Button sx={styles.actionButton} startIcon={<AddIcon />} onClick={() => handleModalOpen(null)}>Add a New Address</Button>

            {/* Add/Edit Address Modal */}
            <Modal
  aria-labelledby="transition-modal-title"
  aria-describedby="transition-modal-description"
  open={modalOpen}
  onClose={handleModalClose}
  closeAfterTransition
>
  <Box sx={styles.modalStyle}>
    <Typography sx={{ color: '#283246', fontWeight: 600, fontSize: '1.25rem' }}>
      {currentEditAddress ? 'Edit Address' : 'Add New Address'}
    </Typography>
    <IconButton sx={{ position: 'absolute', right: 15 }} onClick={handleModalClose}>
      <CloseIcon />
    </IconButton>
    <Divider />
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <InputLabel sx={{ ml: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>
            Country
          </InputLabel>
          <TextField
            name="country"
            value={newAddress.country}
            onChange={handleAddressChange}
            displayEmpty
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                width: "237px",
                height: "46px",
                "& input": {
                  padding: "0.8rem",
                  height: "auto",
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: "0.8rem",
                fontWeight: 500,
                fontFamily: 'DM Sans, Arial, sans-serif'
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: '#97A1B6',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ ml: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>
            State
          </InputLabel>
          <TextField
            id="state"
            name="state"
            value={newAddress.state}
            onChange={handleAddressChange}
            sx={{ width: '100%', "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ ml: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>
            City
          </InputLabel>
          <TextField
            id="city"
            name="city"
            value={newAddress.city}
            onChange={handleAddressChange}
            sx={{ width: '100%', "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ ml: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>
            ZIP Code
          </InputLabel>
          <TextField
            id="zip_code"
            name="zip_code"
            value={newAddress.zip_code}
            onChange={handleAddressChange}
            sx={{ width: '100%', "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ ml: 1, display: 'flex', fontSize: '0.75rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif' }}>
            Street Address
          </InputLabel>
          <TextField
            id="street_address"
            name="street_address"
            value={newAddress.street_address}
            onChange={handleAddressChange}
            sx={{ width: '100%', "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: 14,
              fontWeight: 400,
              borderRadius: '12px',
              width: 150,
              height: 45,
              background: 'linear-gradient(to right, rgba(83, 90, 106, 1)  10%, rgba(40, 50, 70, 1) 100%)'
            }}
            onClick={saveAddress}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Box>
</Modal>

            {/*update quantity*/ }
            <Modal open={quantityModelOpen} onClose={handleModalCloseQuantity}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "20%", bgcolor: "background.paper", boxShadow: "1.5rem", minHeight: "25%", borderRadius: "12px", display: "flex", flexDirection: "column" }}>
          <Grid>
            <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0.875rem" }}>
              <Typography sx={{ color: '#283246', fontWeight: 600, fontSize: '1.25rem' }}>Update quantity</Typography>
              <IconButton onClick={handleModalCloseQuantity}><CloseIcon /></IconButton>
            </Box>
            <Divider />
            <Box>
              <Box sx={{ margin: 'auto', width: '60%', justifyContent: 'center', padding: "0.2rem" }}>
                <Typography sx={{ color: '#535A6A', fontFamily: "DM Sans, Arial, sans-serif" }}> {modalDevice === 'AxonsMicro' ? 'Axons Micro' : 'Axons Micro Pro'}</Typography>
                <Select
                  value={quantity}
                  onChange={handleQuantityChange}
                  sx={{ "& .MuiOutlinedInput-notchedOutline": { border: `1px solid #97A1B6`, borderRadius: "0.7rem" }, width: '100%' }}
                >
                  {[0, 100, 200, 500].map((value) => (
                    <MenuItem key={value} value={value} sx={{ width: "100%", maxWidth: 400, fontSize: "0.813rem", color: "#535A6A", fontWeight: 500 }}>{value}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box sx={{ display: "flex", padding: "1rem" }}>
              <Button onClick={()=>handleUpdate(modalDevice)} style={{color: "white", marginTop: "1rem", borderRadius: "0.75rem", width: "40%", height: "2.875rem", textTransform: "none", background: "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)", margin: "auto"}}>Update</Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
          </Box>
          {selectedDevices?.AxonsMicro === 0 && selectedDevices?.AxonsMicroPro === 0 && <Typography sx={{fontSize: '1rem',
                  fontWeight: 400,
                  fontFamily: "DM Sans, Arial, sans-serif",
                  color: "#d32f2f",}}>Please go back and select devices to proceed.</Typography>}
{validationError && <Typography sx={{ fontWeight: 400,
                  fontFamily: "DM Sans, Arial, sans-serif",
                  color: "#d32f2f",}}>{validationError}</Typography>}
          <Grid item xs={12} sx={{ display: 'flex', p: 2 ,mt:5}}>
            <Button sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: "12px",
              width: "200px",
              height: 45,
              textTransform:'none',
              mr: 5,
              backgroundColor: "#F8F8F8",
              color: "#535A6A",
              border: "1px solid #DDDEE1",
            }} startIcon={<ArrowBackIcon />} onClick={handleBack}>Back</Button>
            {selectedDevices?.AxonsMicro === 0 && selectedDevices?.AxonsMicroPro === 0?'':
              <Button variant="contained" sx={{
                fontSize: 14,
                fontWeight: 400,
                borderRadius: "12px",
                width: "200px",
                height: 45,
                textTransform:'none',
                background: "linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)",
              }} endIcon={<ArrowForwardIcon />} onClick={handleContinue}
              >Continue</Button>
            }
          
          </Grid>
        </Paper>
    </>
  );
}
