import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AxonsMicro from "../../assets/image/Axons-Micro.png";
import AxonsMicroPro from "../../assets/image/Axons-Micro-Pro.png";
import DataContext from "../../DataContext";
import { Paper } from "@mui/material";
import { useEffect } from "react";

const strikethroughStyle = {
  textDecoration: "line-through",
  color:'#535A6A',
marginLeft:'2%'}


export const devicePrices = {
  AxonsMicro: {
    0: { actualPrice: 0, discountedPrice: 0, savings: 0},
    100: { actualPrice: 0, discountedPrice: 0, savings: 0},
    200: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    500: { actualPrice: 0, discountedPrice: 0, savings: 0 },
  },
  AxonsMicroPro: {
    0: { actualPrice: 0, discountedPrice: 0, savings: 0},
    100: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    200: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    500: { actualPrice: 0, discountedPrice: 0, savings: 0 },
  },
};

export const SelectDevice = ({ setActiveStep, onNext, onBack }) => {

  const {selectedDevices, setSelectedDevices,setProductPrice,productKey} = React.useContext(DataContext)
  
  const handleContinue = () => {
    if (
      selectedDevices.AxonsMicro === 0 &&
      selectedDevices.AxonsMicroPro === 0
    ) {
      alert("Please select at least one device quantity before continuing.");
    } else {
      setActiveStep(2);
      onNext()
    }
  };

  const handleBack = () => {
    setActiveStep(0);
    onBack()
  };

   // Function to update the discountedPrice in devicePrices
   function updateDevicePrices(prices, deviceData) {
    const updatedPrices = { ...prices };
  
    deviceData?.products?.forEach(product => {
      const { name, price } = product;
  
      // Remove spaces from the name to match the key in updatedPrices
      const keyName = name.replace(/\s+/g, "");
  
      // Check if the key exists in the updatedPrices
      if (updatedPrices[keyName]) {
        Object.keys(updatedPrices[keyName]).forEach(key => {
          const quantity = parseInt(key, 10);
          const discountedPrice = price * quantity;
  
          // Update the discountedPrice
          updatedPrices[keyName][key].discountedPrice = discountedPrice;
  
          // Calculate the actualPrice as 25% more than discountedPrice
          const actualPrice = discountedPrice * 1.25;
  
          // Update the actualPrice
          updatedPrices[keyName][key].actualPrice = actualPrice;
  
          // Calculate the savings
          const savings = actualPrice - discountedPrice;
  
          // Update the savings
          updatedPrices[keyName][key].savings = savings;
        });
      }
    });
  
    return updatedPrices;
  }

  const updatedDevicePrices = updateDevicePrices(devicePrices,productKey);

  useEffect(()=>{
if(devicePrices){
  setProductPrice(updatedDevicePrices)
}

// eslint-disable-next-line
  },[updatedDevicePrices])



  const handleChange = (device, quantity) => {

    setSelectedDevices((prev) => ({
      ...prev,
      [device]: quantity,
    }));
  };

  const renderDeviceCard = (device, deviceName) => {
    const quantity = selectedDevices[device];
    const prices = devicePrices[device][quantity] || {};
    return (
      <Card sx={{ boxShadow: "none", textAlign: 'center' }}>
        <img
          width={258.95}
          height={195}
          src={device === "AxonsMicro" ? AxonsMicro : AxonsMicroPro}
          alt={deviceName}
          style={{display:'flex'}}
          
        />
        <Typography
          sx={{
            mt: 2,
            fontSize: 20,
            fontWeight: 700,
            fontFamily: "DM Sans, Arial, sans-serif",
            color: "#000000",
            display:'flex'
          }}
        >
          {deviceName}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
    fontWeight: 400,
    fontFamily: "DM Sans, Arial, sans-serif",
    height: 48,
    lineHeight: "15.86px",
    color: "#535A6A",
    mt: 1,
    mb: 2,
    mr: 3,
    textAlign: "left",
    width:'90%'

          }}
        >
          {device === "AxonsMicro"
            ? "The Axons Micro is a versatile IoT device designed to seamlessly connect and manage data from various sensors, enhancing both collection and security capabilities in real-time cloud storage."
            : "The Axons Micro Pro offers advanced connectivity options, tailored to handle complex data operations efficiently. Ideal for environments demanding rigorous data management and increased security."}
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontSize: '0.875rem',
            fontWeight: 700,
            fontFamily: "DM Sans, Arial, sans-serif",
            display:'flex',
          }}
        >
          Select Quantity
        </Typography>
        <FormControl sx={{ mt:1, maxWidth: 120,display:'flex', }} size="small">
          <Select
            sx={{ borderRadius: 10, width: '100%' }}
            value={quantity}
            onChange={(e) => handleChange(device, e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          > 
          <MenuItem value={0}>0</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={500}>500</MenuItem>
          </Select>
        </FormControl>
        {quantity > 0 ? (
          <>
           <Typography
  sx={{ 
    fontSize: 17, 
    fontWeight: 700,  
    fontFamily: "DM Sans, Arial, sans-serif", 
    mt: 1,
    display: 'flex'
  }}
>
  Now ${prices.discountedPrice}{" "}
  <span style={strikethroughStyle}>
    ${prices.actualPrice}
  </span>
</Typography>

<Typography
  sx={{
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "DM Sans, Arial, sans-serif",
    color: "text.secondary",
    mt: 0.5,
    display: 'flex'
  }}
>
  <span style={{ 
    backgroundColor: "#f4c430", // Light yellow background for highlight
    color: "#283246", 
    fontWeight: 600,
    fontSize: '1rem',
    padding: "0 4px",
    borderRadius: "4px"
  }}>
    You save ${prices.savings}
  </span>
</Typography>

          </>
        ):
        <>
        <Typography
          sx={{ fontSize: 17, fontWeight: 700,  fontFamily: "DM Sans, Arial, sans-serif", mt: 1 ,display:'flex'}}
        >
           ${prices.discountedPrice}
        </Typography>
      </>
        }
      </Card>
    );
  };


  return (
       <Paper sx={{ p: 2, borderRadius: "12px",boxShadow:'none' }}>
        <Typography  sx={{
            color: "#283246",
            fontSize: "1.625rem",
            fontWeight: 700,
            fontFamily: "DM Sans, Arial, sans-serif",
            mb: 2,
            mt:2,
            display: 'flex',
          }}>
          Select Your Device
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ borderRight: { md: "1px solid #D9D9D9" } }}>
            {renderDeviceCard("AxonsMicro", "Axons Micro")}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderDeviceCard("AxonsMicroPro", "Axons Micro Pro")}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex',mt:10 }}>
          <Button
             sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: "12px",
              width: "200px",
              height: 45,
              mr: 5,
              backgroundColor: "#F8F8F8",
              color: "#535A6A",
              border: "1px solid #DDDEE1",
              textTransform:'none',
            }}
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: "12px",
              width: "200px",
              height: 45,
              textTransform:'none',
              background: "linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)",
            }}
            endIcon={<ArrowForwardIcon />}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Grid>
      </Paper>
  );
};
