import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataContext from "../../DataContext";
import { Paper } from "@mui/material";
import checked from "../../assets/icons/checked.svg"
import unchecked from "../../assets/icons/unchecked.svg"
import { useEffect } from "react";


export const plans = {
  monthly: [
    {
      name: "Basic",
      price: 0,
      features: ["User Administration", "Device Control Hub", "Basic Map"],
      selected: false,
    },
    {
      name: "Advanced",
      price: 0,
      features: [
        "User Administration",
        "Device Control Hub",
        "Data Insights",
        "Geospatial Mapping",
      ],
      selected: false,
    },
    {
      name: "Premium",
      price: 0,
      features: [
        "User Administration",
        "Device Control Hub",
        "Data Insights",
        "Geospatial Mapping",
        "Consumer App",
        "Connected Vehicles",
        "API Access",
      ],
      selected: false,
    },
  ],
  yearly: [
    {
      name: "Basic",
      price: 0,
      features: ["User Administration", "Device Control Hub", "Basic Map"],
      selected: false,
    },
    {
      name: "Advanced",
      price: 0,
      features: [
        "User Administration",
        "Device Control Hub",
        "Data Insights",
        "Geospatial Mapping",
        "Consumer App",
      ],
      selected: true,
    },
    {
      name: "Premium",
      price: 0,
      features: [
        "User Administration",
        "Device Control Hub",
        "Data Insights",
        "Geospatial Mapping",
        "Consumer App",
        "Connected Vehicles",
        "API Access",
      ],
      selected: false,
    },
  ],
};




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Subscription({ setActiveStep, onNext, onBack }) {
  const { value, setValue, selectedPlan, setSelectedPlan ,setPlanPrice,planPrice,productKey} = React.useContext(DataContext);
  const updatePlansWithBackendPrices = (plans, productKey) => {
    // Helper function to find the price from backend data
    const findPrice = (name, duration) => {
      const backendPlan = productKey.plans.find(
        (plan) => plan.name.includes(name) && plan.name.includes(duration)
      );
      return backendPlan ? backendPlan.price : null;
    };
  
    return {
      monthly: plans.monthly.map((plan) => ({
        ...plan,
        price: findPrice(plan.name, "Month"),
      })),
      yearly: plans.yearly.map((plan) => ({
        ...plan,
        price: findPrice(plan.name, "Year"),
      })),
    };
  };
  
  // Update the plans with backend prices

  // const updatedPlans = updatePlansWithBackendPrices(plans, productKey);

useEffect(()=>{
console.log('heyy')

const updatedPlans = updatePlansWithBackendPrices(plans, productKey);

setPlanPrice(updatedPlans)
console.log(updatedPlans,'plansupdate')

},[])


  const handleContinue = () => {
    setActiveStep(3);
    onNext()
  };

  const handleBack = () => {
    setActiveStep(1);
    onBack()
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const defaultPlan = planPrice?.[newValue === 0 ? "monthly" : "yearly"].find(
      (plan) => plan.selected
    )
    setSelectedPlan(defaultPlan ? defaultPlan.name : null);
  };

  const handlePlanSelect = (planName) => {
    setSelectedPlan(planName);
  };

  const renderPlans = (planType) => {
    return planPrice?.[planType]?.map((plan, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card
          sx={{
            boxShadow: "none",
            border: `3px solid ${
              selectedPlan === plan.name ? "#F99494" : "#DDDEE1"
            }`,
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            cursor: "pointer",
            ":hover": {
              border: `3px solid ${
                selectedPlan === plan.name ? "#F99494" : "#A9A9A9"
              }`,
            },
          }}
          onClick={() => handlePlanSelect(plan.name)}
        >
          <Box
            sx={{
              m: "12px",
              bgcolor: selectedPlan === plan.name ? "#F99494" : "#F8F8F8",
              borderRadius: "15px",
              height: 119,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: 700,
                fontFamily: "DM Sans, Arial, sans-serif",
                color: selectedPlan === plan.name ? "#fff" : "#535A6A",
                textAlign: "center",
              }}
            >
              {plan.name}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                color: selectedPlan === plan.name ? "#fff" : "#535A6A",
                textAlign: "center",
              }}
            >
              {value === 0 ? "PER MONTH" : "PER YEAR"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: 23,
                fontWeight: 700,
                fontFamily: "DM Sans, Arial, sans-serif",
                color: "#535A6A",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  border: `3px solid ${
                    selectedPlan === plan.name ? "#F99494" : "#DDDEE1"
                  }`,
                  color: selectedPlan === plan.name ? "#F99494" : "#535A6A",
                  borderRadius: "60%",
                  padding: "28px 15px",
                  background: "#fff",
                  width: '60px',
                  display: 'flow'
                }}
              >
                ${plan.price}
              </span>
            </Typography>
          </Box>
          <List
            sx={{
              mt: 4,
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
            aria-label="contacts"
          >
            {plan.features.map((feature, idx) => (
              <ListItem disablePadding key={idx}>
                <ListItemButton>
                  <ListItemIcon>
                    {selectedPlan === plan.name ? (
                      <img src={checked} alt="checked"/>
                    ) : (
                      <img src={unchecked} alt="unchecked"/>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={feature} sx={{fontSize:'1rem',fontWeight:400, fontFamily: "DM Sans, Arial, sans-serif",color:'#535A6A'}}/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
    ));
  };


  return (
    <Paper sx={{ p: 2, borderRadius: "12px",boxShadow:'none' }}>
        <Typography sx={{ color: "#283246", fontSize: 26, fontWeight: 700 ,display:'flex'}}>
          Select Your Subscription Plan
        </Typography>
        <Typography
          sx={{
            p: "2px",
            borderRadius: "10px",
            bgcolor: "#F99494",
            color:'#FFFFFF',
            fontSize: 16,
            fontWeight: 700,
            width: "450px",
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"}}
        >
          <span style={{ fontSize: 25, fontWeight: 700, color: "#fff" }}>
            $500
          </span>{" "}
          <span style={{ marginLeft: "10px", color: "#fff" }}>
            Axons Basic Licence Fee (One-Time Charge)
          </span>
        </Typography>

        <Box sx={{ mt: "20px",width:'100%' }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Tabs
              value={value}
              // onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                bgcolor: "#F8F8F8",
                borderRadius: "12px",
                border: "1px solid #DDDEE1",
                '& .MuiTabs-indicator': {
                  display: 'none',
                }
              }}
            >
              {/* <Tab
                label="Monthly billing"
                {...a11yProps(0)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#FFFFFF',
                    color: '#535A6A',
                    borderRadius: '12px',
                    border: '1px solid #DDDEE1',
                    textTransform:'none'
                  },
                  color: '#535A6A',
                  textTransform:'none'
                }}
              /> */}
              <Tab label="Yearly (Save up to 30%)" {...a11yProps(1)}  sx={{
                  // '&.Mui-selected': {
                  //   backgroundColor: '#FFFFFF',
                  //   color: '#535A6A',
                  //   borderRadius: '12px',
                  //   border: '1px solid #DDDEE1',
                  //   textTransform:'none',
                  // },
                  backgroundColor: '#FFFFFF',
                  color: '#535A6A',
                  borderRadius: '12px',
                  border: '1px solid #DDDEE1',
                  textTransform:'none',
                  color: '#535A6A',
                  textTransform:'none',
                  width:'300px'
                }} />
            </Tabs>
          </Box>

          {/* <CustomTabPanel value={value} index={0}> */}
            {/* <Grid container spacing={2} sx={{ justifyContent: "center",width:'100%' }}>
              {renderPlans("monthly")}
            </Grid> */}
          {/* </CustomTabPanel> */}

          <CustomTabPanel value={value} index={1}>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              {renderPlans("yearly")}
            </Grid>
          </CustomTabPanel>
        </Box>

     
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Button
             sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: "12px",
              width: "200px",
              height: 45,
              mr: 5,
              backgroundColor: "#F8F8F8",
              color: "#535A6A",
              border: "1px solid #DDDEE1",
              textTransform:'none',
            }}
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: 14,
                fontWeight: 400,
                borderRadius: "12px",
                width: "200px",
                height: 45,
                textTransform:'none',
                background:
                selectedPlan ?"linear-gradient(to right, rgba(83, 90, 106, 1) 0%, rgba(40, 50, 70, 1) 100%)":'gray',              }}
              endIcon={<ArrowForwardIcon />}
              onClick={handleContinue}
              disabled={!selectedPlan}
            >
              Continue
            </Button>
          </Grid>
 
      </Paper>
  );
}
