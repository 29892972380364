import { createContext, useState, useEffect } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const getInitialState = (key, defaultValue) => {
    const savedValue = localStorage.getItem(key);
    try {
      return savedValue ? JSON.parse(savedValue) : defaultValue;
    } catch (e) {
      console.error(`Error parsing localStorage key "${key}":`, e);
      return defaultValue;
    }
  };

  const [email, setEmail] = useState(getInitialState(""));
  const [newOtp,setNewOtp] = useState('')
  const [uniqueId, setUniqueId] = useState(getInitialState("uniqueId", ""));
  const [otpResend, setOtpResend] = useState(getInitialState("otpResend", ""));
  const [formData, setFormData] = useState(getInitialState("formData", {
    company_name: "",
    primary_email: "",
    country: "",
    state: "",
    city: "",
    street_address: "",
    zip_code: "",
    useDefaultEmail: false,
    useForShipping: false,
  }));
  const [productPrice, setProductPrice] = useState(getInitialState("productPrice", ""));
  const [planPrice, setPlanPrice] = useState(getInitialState("planPrice", ""));
  const [selectedDevices, setSelectedDevices] = useState(getInitialState("selectedDevices", {
    AxonsMicro: 100,
    AxonsMicroPro: 100,
  }));
  const [productKey, setProductKey] = useState(getInitialState("productKey", ""));
  const [value, setValue] = useState(getInitialState("value", 0));
  const [selectedPlan, setSelectedPlan] = useState(getInitialState("selectedPlan", "Advanced"));
  const [clientSecret, setClientSecret] = useState(getInitialState("clientSecret", ""));
  const [invoicePdf, setInvoicePdf] = useState(getInitialState("invoicePdf", ''));

  useEffect(() => {
    localStorage.setItem("productPrice", JSON.stringify(productPrice));
  }, [productPrice]);

  useEffect(() => {
    localStorage.setItem("invoicePdf", JSON.stringify(invoicePdf));
  }, [invoicePdf]);

  useEffect(() => {
    localStorage.setItem("uniqueId", JSON.stringify(uniqueId));
  }, [uniqueId]);

  useEffect(() => {
    localStorage.setItem("otpResend", JSON.stringify(otpResend));
  }, [otpResend]);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem("selectedDevices", JSON.stringify(selectedDevices));
  }, [selectedDevices]);

  useEffect(() => {
    localStorage.setItem("productKey", JSON.stringify(productKey));
  }, [productKey]);

  useEffect(() => {
    localStorage.setItem("value", JSON.stringify(value));
  }, [value]);

  useEffect(() => {
    localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
  }, [selectedPlan]);

  useEffect(() => {
    localStorage.setItem("clientSecret", JSON.stringify(clientSecret));
  }, [clientSecret]);

  return (
    <DataContext.Provider
      value={{
        setEmail,
        email,
        setUniqueId,
        uniqueId,
        otpResend,
        setOtpResend,
        formData,
        setFormData,
        productPrice,
        setProductPrice,
        selectedDevices,
        setSelectedDevices,
        productKey,
        setProductKey,
        value,
        setValue,
        selectedPlan,
        setSelectedPlan,
        clientSecret,
        setClientSecret,
        setInvoicePdf,
        invoicePdf,
        newOtp,
        setNewOtp,
        setPlanPrice,
        planPrice,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
