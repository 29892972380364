// src/api/queries.js
import axiosInstance from "./axios";

export const loginUser = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, email] = queryKey;
  const response = await axiosInstance.post("otp/request", {
    email,
  });
  return response.data;
};

export const loginOTP = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, email, otp] = queryKey;
  const response = await axiosInstance.post("otp/validate", {
    email,
    otp,
  });
  return response.data;
};

const transformFormData = (formData) => {
  const {
    company_name,
    primary_email,
    country,
    state,
    city,
    street_address,
    zip_code,
    useForShipping,
  } = formData;

  const payload = {
    business_name: company_name,
    business_email: primary_email,
    billing_address: {
      country,
      state,
      city,
      street_address: street_address,
      zip_code: zip_code,
    },
  };

  if (useForShipping) {
    payload.shipping_address = {
      country,
      state,
      city,
      street_address: street_address,
      zip_code: zip_code,
    };
  }

  return payload;
};

export const setupProfile = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, formData, uniqueId] = queryKey;

  const response = await axiosInstance.post(
    `company/${uniqueId}/profile`,
    transformFormData(formData)
  );
  return response.data;
};

export const getProducts = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key] = queryKey;

  const response = await axiosInstance.post(`products`);
  return response.data.data;
};

export const postCompanyCart = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key,datapayload,uniqueId] = queryKey;

  const response = await axiosInstance.post(`company/${uniqueId}/cart`,datapayload);
  return response.data.data;
};


const PostCheckoutPayload = (stripeIntent)=>{
  const {
    payment_method,
    payment_method_types
  } = stripeIntent;

  const payload = {
    method_type:payment_method_types[0],
    source_id:payment_method
  }
  return payload
}

export const PostCheckout = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key,stripeIntent,uniqueId] = queryKey;

  const response = await axiosInstance.post(`company/${uniqueId}/checkout`,PostCheckoutPayload(stripeIntent));
  return response.data.data;
};

