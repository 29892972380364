import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useQuery } from "react-query";
import { loginUser } from "../api/querise";
import DataContext from "../DataContext";
import axonslablogin from "../assets/image/axonslablogin.svg";
import axonslogin from "../assets/image/axonslogin.svg";
import axonslogin2 from "../assets/image/axonslogin2.svg";
import axonslogin3 from "../assets/image/axonslogin3.svg";
import axonslogin4 from "../assets/image/axonslogin4.svg";
import axonslogin5 from "../assets/image/axonslogin5.svg";
import axonslogin6 from "../assets/image/axonslogin6.svg";
import { useSnackbar } from "../hooks/Snackbar";
import { CircularProgress } from "@mui/material";


const images = [
  axonslogin,
  axonslogin2,
  axonslogin3,
  axonslogin4,
  axonslogin5,
  axonslogin6,
];

const Login = ({ setEmailAuth }) => {
  const { email, setEmail,setOtpResend,setNewOtp } = useContext(DataContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shouldPost, setShouldPost] = useState(false);
  const showSnackbar = useSnackbar();

  const { data, error, isLoading } = useQuery(["users", email], loginUser, {
    enabled: shouldPost,
    retry: false,
  });



  useEffect(() => {
    if (data) {
      setShouldPost(false);
      setNewOtp(data?.data?.otp)
      setOtpResend(data?.data?.otp_interval_time)
      setEmailAuth(true);
    } else if (error) {
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");
      setShouldPost(false);
    }

    // eslint-disable-next-line
  }, [data, error]);

  const handleClick = async () => {
    localStorage.setItem("email", email);
    setShouldPost(false); // Reset before enabling again
    setShouldPost(true); // Enable query
  };

  const extendedImages = [...images, images[0]];

  const handleChangeCarousal = (index) => {
    if (index === images.length) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    if (currentSlide === 0) {
      const carouselElement = document.querySelector('.carousel');
      carouselElement.style.transition = 'none';
      carouselElement.style.transform = 'translate3d(0px, 0px, 0px)';

      setTimeout(() => {
        carouselElement.style.transition = '';
      }, 0);
    }
  }, [currentSlide]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <Grid
      container
      sx={{ bgcolor: "#FFFFFF", padding: "1rem" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        sx={{ justify: "center", alignItems: "center", alignContent: "center" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "auto", md: "90vh" },
          }}
        >
           <Box>
          <img src={axonslablogin} alt="axonslablogin" />
        </Box>
          <Card
            sx={{
              width: 440,
              height: 320,
              maxWidth: "100%",
              borderRadius: "25px",
              align: "center",
              justify: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  fontSize: 22,
                  fontWeight: 700,
                  fontFamily: "DM Sans, Arial, sans-serif",
                  color: "#535A6A",
                }}
              >
                Sign in
              </Typography>
              <Typography
               sx={{
                fontSize: 12,
                fontWeight: 500,
                fontFamily: "DM Sans, Arial, sans-serif",
                color: "#535A6A",
              }}
              >
                Create your account and start exploring our IoT solutions.
              </Typography>
              <Box sx={{ padding: "0.6rem" }}>
              <Typography
                sx={{
                  fontFamily: "DM Sans, Arial, sans-serif",
                  color: "#535A6A",
                  fontSize: "12px",
                  marginLeft: "0.4rem",
                  display: 'flex'
                }}
              >
                Email
              </Typography>
                <TextField
                  style={{ width: 380, borderRadius: 12 }}
                  placeholder="Enter your email"
                  id="margin-normal"
                  margin="normal"
                  sx={{
                    mt: 1,
                    mb:1,
                    width:'100%',
                    // height:'40px',
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      border:'1px solid',
                      "& input": {
                        padding: "0.8rem",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#535A6A",
                    },
                  }}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setEmail(e.target.value, "eeee")}
                />
              </Box>
              <Button
                variant="contained"
                sx={{
                  mt:1,
                  borderRadius: "12px",
                  width: "80%",
                  height: 55,
                  textTransform:'none',
                  background:
                    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                  },
                }}
                onClick={handleClick}
                disabled={isLoading}
              >

{isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                'Get Started'
              )}

                
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
          mt: { xs: 5, md: 2 },
        }}
      >
        <Card
          sx={{
            width: '90%',
            borderRadius: "15px",
            marginLeft: '4rem'
          }}
        >
          <Carousel
            selectedItem={currentSlide}
            onChange={handleChangeCarousal}
            autoPlay
            infiniteLoop
            interval={2000}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
          >
            {extendedImages.map((URL, index) => (
              <div className="slide" key={index}>
                <img alt="carousel_image" src={URL} />
              </div>
            ))}
          </Carousel>
        </Card>
      </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
