import React from "react";
import { Snackbar, SnackbarContent, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

export const successToastConfig = {
  duration: 2000,
  style: {
    background: "#E1FAEB",
    color: "#1F1F1F",
    borderRadius: "10px",
    padding: "9px",
    border: "1px solid #048236",
  },
  iconTheme: {
    primary: "#048236",
  },
  className: "toast-success",
};

export const errorToastConfig = {
  duration: 5000,
  style: {
    background: "#FFE6E6",
    color: "#1F1F1F",
    borderRadius: "10px",
    padding: "8px",
    border: "1px solid #F93232",
  },
  iconTheme: {
    primary: "#F93232",
  },
  className: "toast-error",
};

const styles = {
  success: {
    backgroundColor: '#E1FAEB',
    color: "#1F1F1F",
    borderRadius: "10px",
    padding: "9px",
    border: "1px solid #048236",
  },
  error: {
    background: "#FFE6E6",
    color: "#1F1F1F",
    borderRadius: "10px",
    padding: "8px",
    border: "1px solid #F93232",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "8px",
  },
  iconSuccess: {
    color: "#048236", // Green color for success icon
  },
  iconError: {
    color: "#F93232", // Red color for error icon
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
};

function CustomSnackbar({ message, onClose, variant, open }) {
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <SnackbarContent
        style={styles[variant]}
        message={
          <span style={styles.message}>
            <Icon
              style={{
                ...styles.icon,
                ...styles.iconVariant,
                ...(variant === "success" ? styles.iconSuccess : styles.iconError)
              }}
            />
           <Typography sx={{fontsize:'1rem',fontFamily:"DM Sans, Arial, sans-serif",color:'#535A6A'}}>{message}</Typography> 
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="#535A6A"
            onClick={onClose}
          >
            <CloseIcon style={styles.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default CustomSnackbar;
